import state from './moduleAddressState'
import mutations from './moduleAddressMutations'
import actions from './moduleAddressActions'
import getters from './moduleAddressGetters'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
