export default [
  {
    path: '/reports',
    name: 'report',
    meta: {
      breadcrumb: [
        { text: 'report.title', active: true },
      ],
      pageTitle: 'report.title',
      roles: ['ROLE_DATA_ANALYST'],
    },
    component: () => import('@/views/reports/ReportView'),
  },
]
