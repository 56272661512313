<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <component :is="layout">
      <router-view />
    </component>

  </div>
</template>

<script>

import PusherClient from '@/libs/pusher'
import BeamsClient from '@/libs/beams'
// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import login from '@/http/requests/auth/login'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

window.onbeforeunload = () => {
  BeamsClient.stopBeams()
}

export default {
  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
    me() {
      return this.$store.getters['auth/getUser']
    },
    config() {
      return this.$store.state.appConfig.organization.config
    },
  },
  watch: {
    me(newUser, oldUser) {
      if (newUser.id !== oldUser) {
        this.$store.dispatch('appConfig/fetchOrganizationConfig')
          .then(() => {
            PusherClient.init(this.config.notifications.pusher.key)
            BeamsClient.init(this.config.notifications.beams.instanceId)
          })
      }
    },
  },
  created() {
    login.init()
    this.setupNotifications()
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  beforeMount() {
    Promise.all([
      this.$store.dispatch('appConfig/fetchOrganizationInfo'),
      this.$store.dispatch('auth/fetchUserInfo'),
      this.$store.dispatch('auth/fetchUserTeam'),
    ]).then(() => {
      if (this.$router.currentRoute.name === 'login') {
        this.$router.push('/')
      }
    }).catch(() => {})
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()
    login.init(store)

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
    }
  },
  methods: {
    setupNotifications() {
      window.toast = Object.freeze({
        notify: {
          success: (header, message) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: header,
                text: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          },
          warning: (header, message) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: header,
                text: message,
                icon: 'AlertTriangleIcon',
                variant: 'warning',
              },
            })
          },
          danger: (header, message) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: header,
                text: message,
                icon: 'XIcon',
                variant: 'danger',
              },
            })
          },
        },
      })
    },
  },
}
</script>
