import { cloneNested } from '@/helpers/helpers'

const teamNameModel = {
  name: '',
}
const checklistDictionaryModel = {
  value: '',
  status: false,
}

export default {
  teamName: cloneNested(teamNameModel),
  emptyTeamName: cloneNested(teamNameModel),
  teamNames: [],
  freeTeamNames: [],
  checklistDictionary: cloneNested(checklistDictionaryModel),
  emptyChecklistDictionary: cloneNested(checklistDictionaryModel),
  checklistDictionaries: [],
}
