/*= ========================================================================================
  File Name: moduleAuthMutations.js
  Description: Auth Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== */

import axios from '@/libs/axios'

export default {
  SET_TOKEN(state, payload) {
    localStorage.setItem('tokens', JSON.stringify(payload))
    axios.defaults.headers.common.Authorization = `Bearer ${`${payload.token}`}`
  },
  SET_USER_INFO(state, payload) {
    localStorage.setItem('userInfo', JSON.stringify(payload))
    state.activeUser = payload
  },
  SET_SHOW_INIT_TEAM_POPUP(state, payload) {
    state.showInitTeamPopup = payload
  },
  SET_USER_TEAM(state, payload) {
    localStorage.setItem('userTeam', JSON.stringify(payload))
    state.userTeam = payload
    state.teamLoaded = true
  },
  SET_USER_TEAM_STATUS(state, status) {
    state.userTeam.status = status
    localStorage.setItem('userTeam', JSON.stringify(state.userTeam))
  },
  CLEAR_USER_TEAM(state) {
    localStorage.setItem('userTeam', JSON.stringify(state.defaultUserTeam))
    state.userTeam = state.defaultUserTeam
  },
  REMOVE_USER_TOKEN() {
    localStorage.removeItem('tokens')
    delete axios.defaults.headers.common.Authorization
  },
  REMOVE_USER_INFO_AND_TEAM() {
    localStorage.removeItem('userInfo')
    localStorage.removeItem('userTeam')
  },
  SET_NOTIFICATIONS(state, payload) {
    state.notifications = payload
  },
  ADD_NOTIFICATION(state, payload) {
    state.notifications.unshift(payload)
  },
  MARK_NOTIFICATION_AS_READ(state, id) {
    state.notifications.forEach((notification, key) => {
      if (notification.id === id) {
        state.notifications[key].unreaded = false
      }
    })
  },
}
