import axios from '@/libs/axios'
import router from '@/router'

let isAlreadyFetchingAccessToken = false
let subscribers = []

function onAccessTokenFetched(accessToken) {
  subscribers = subscribers.filter(callback => callback(accessToken))
}

function addSubscriber(callback) {
  subscribers.push(callback)
}

export default {
  init(store) {
    axios.interceptors.response.use(response => response, error => {
      const { config, response } = error
      const originalRequest = config
      if (!!response && [401].includes(response.status) && (typeof response === 'undefined' || ['An authentication exception occurred.', 'Invalid JWT Refresh Token', 'JWT Refresh Token Not Found'].includes(response.data.message))) {
        store.dispatch('auth/logout').then(() => router.push({ name: 'login' }))
        localStorage.setItem('previousPath', router.currentRoute.path)
      }

      if (!!response && response.status === 401 && ['Expired JWT Token'].includes(response.data.message)) {
        if (!isAlreadyFetchingAccessToken) {
          isAlreadyFetchingAccessToken = true
          store.dispatch('auth/fetchAccessTokenByRefreshToken')
            .then(accessToken => {
              onAccessTokenFetched(accessToken)
              store.dispatch('auth/fetchUserInfo')
            })
            .finally(() => {
              isAlreadyFetchingAccessToken = false
            })
        }

        return new Promise(resolve => {
          addSubscriber(accessToken => {
            originalRequest.headers.Authorization = `Bearer ${accessToken.data.token}`
            resolve(axios(originalRequest))
          })
        })
      }

      if (response) {
        return Promise.reject(response.data)
      }

      return Promise.reject(new Error('Unknown connection error'))
    })
  },
  login(username, password) {
    return axios.post('/auth/token', {
      username,
      password,
    })
  },
  userInfo() {
    return axios.get('/user/me')
  },
  userCurrentTeam() {
    return axios.get('/user/me/current-team')
  },
  userNotifications() {
    return axios.get('/user/me/notifications')
  },
  changeStatus: (id, data) => axios.patch(`/team/${id}/change-status`, data),
  markNotificationAsRead: id => axios.get(`/user/notification/read/${id}`),
  refreshToken() {
    const tokens = JSON.parse(localStorage.getItem('tokens'))
    if (!tokens) {
      return new Promise((res, rej) => {
        if (localStorage.getItem('userInfo')) {
          localStorage.removeItem('userInfo')
        }
        if (localStorage.getItem('tokens')) {
          localStorage.removeItem('tokens')
        }
        router.push({ name: 'login' })
        rej(new Error('Logged Out'))
      })
    }

    return axios.post('/auth/token/refresh', { refreshToken: tokens.refreshToken })
  },
}
