import state from './moduleAttachmentState'
import mutations from './moduleAttachmentMutations'
import actions from './moduleAttachmentActions'
import getters from './moduleAttachmentGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
