export default [
  {
    path: '/team/checklist',
    name: 'team-checklist',
    meta: {
      breadcrumb: [
        { text: 'team.title' },
        { text: 'team.checklist.title', active: true },
      ],
      pageTitle: 'team.checklist.title',
      roles: ['ROLE_PARAMEDIC'],
    },
    component: () => import('@/views/teams/TeamChecklist/TeamChecklist'),
  },
  {
    path: '/team/list',
    name: 'team-list',
    meta: {
      breadcrumb: [
        { text: 'staff.staff_title' },
        { text: 'team.list.title', active: true },
      ],
      pageTitle: 'team.list.title',
      roles: ['ROLE_DISPATCHER', 'ROLE_PARAMEDIC', 'ROLE_TEAM_LEADER'],
    },
    component: () => import('@/views/teams/TeamList/TeamList'),
  },
  {
    path: '/team/view/:id',
    name: 'team-view',
    meta: {
      breadcrumb: [
        { text: 'staff.staff_title' },
        { text: 'team.list.title', to: '/team/list' },
        { text: 'team.title', active: true },
      ],
      pageTitle: 'team.title',
      roles: ['ROLE_DISPATCHER', 'ROLE_PARAMEDIC', 'ROLE_TEAM_LEADER'],
    },
    component: () => import('@/views/teams/TeamView/TeamView'),
  },
]
