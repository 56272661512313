import userRequests from '@/http/requests/user/user'

export default {
  fetchUsers: ({ commit }, payload) => new Promise((resolve, reject) => {
    userRequests.getUsers(payload)
      .then(response => {
        commit('SET_USERS', response.data.items)
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  }),
  fetchUserById: ({ commit }, payload) => new Promise((resolve, reject) => {
    userRequests.getUserById(payload)
      .then(response => {
        commit('SET_USER_EDITED', response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  setUserData: ({ commit }, data) => new Promise(() => {
    commit('SET_USER_EDITED', data)
  }),
  addUser: (_, payload) => new Promise((resolve, reject) => {
    userRequests.addUser(payload)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  updateUser: (_, payload) => new Promise((resolve, reject) => {
    userRequests.updateUser(payload.id, payload.data)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  deleteUser: ({ commit }, id) => new Promise((resolve, reject) => {
    userRequests.deleteUser(id)
      .then(response => {
        commit('REMOVE_USER_RECORD', id)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  changeMyPassword: (_, payload) => new Promise((resolve, reject) => {
    userRequests.updateMyPassword(payload)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  fetchDrivers: ({ commit }) => new Promise((resolve, reject) => {
    userRequests.getFreeDrivers()
      .then(response => {
        commit('SET_DRIVERS', response.data)
        resolve(response.data)
      })
      .catch(err => reject(err))
  }),
  fetchParamedics: ({ commit }) => new Promise((resolve, reject) => {
    userRequests.getFreeParamedics()
      .then(response => {
        commit('SET_PARAMEDICS', response.data)
        resolve(response.data)
      })
      .catch(err => reject(err))
  }),
  fetchMedicineDoctors: ({ commit }) => new Promise((resolve, reject) => {
    userRequests.getFreeMedicineDoctors()
      .then(response => {
        commit('SET_MEDICINE_DOCTORS', response.data)
        resolve(response.data)
      })
      .catch(err => reject(err))
  }),
}
