import axios from '@/libs/axios'

export default {
  getCustomer: id => axios.get(`/customer/${id}`),
  findCustomer: query => axios.get(`/customer/find/${query}`),
  getCustomers: query => axios.get('/customer', { params: query }),
  addCustomer: data => axios.post('/customer', data),
  updateCustomer: (id, data) => axios.patch(`/customer/${id}`, data),
  findCustomerBillingStrategiesByCustomerId: customerId => axios.get(`/customer/billing-strategy/find/${customerId}`),
  updateCustomerBillingStrategies: (id, data) => axios.patch(`/customer/billing-strategy/edit-by-customer/${id}`, data),
}
