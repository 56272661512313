const customerModel = {
  id: null,
  addressLine1: null,
  addressLine2: null,
  zip: null,
  city: null,
  taxNumber: null,
  addresses: [],
}
export default {
  customerEdited: customerModel,
  customerModel,
  customers: [],
}
