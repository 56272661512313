export default {
  teamEdited: {
    status: true,
    teamName: '',
    team: '',
    driverUser: '',
    paramedicUser: '',
    orders: [],
    vehicle: {},
    teamStartedAt: null,
    teamEndedAt: null,
  },
  teams: [],
  activeTeams: [],
  statisticsCurrent: {
    total: 0,
    available: 0,
  },
}
