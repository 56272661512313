import { cloneNested } from '@/helpers/helpers'

export default {
  setFilters: ({ commit, state }, payload) => new Promise((resolve, reject) => {
    try {
      commit('SET_FILTERS', payload)
      localStorage.setItem('filters', JSON.stringify(state))
      resolve(state)
    } catch (error) {
      reject(error)
    }
  }),
  fetchFilters: ({ state }, queryName) => new Promise((resolve, reject) => {
    try {
      const clonedState = cloneNested(state)
      const localStorageFilters = JSON.parse(localStorage.getItem('filters')) ? JSON.parse(localStorage.getItem('filters'))[queryName] : {}
      clonedState[queryName] = { ...state[queryName], ...localStorageFilters }
      resolve(clonedState[queryName])
    } catch (error) {
      reject(error)
    }
  }),
}
