import { cloneNested } from '@/helpers/helpers'

export default {
  SET_VEHICLE_EDITED(state, vehicle) {
    state.vehicleEdited = vehicle
  },
  SET_VEHICLES(state, vehicles) {
    state.vehicles = vehicles
  },
  SET_FREE_VEHICLES(state, vehicles) {
    state.freeVehicles = vehicles
  },
  SET_VEHICLE_STATUSES(state, vehicleStatuses) {
    state.vehicleStatuses = vehicleStatuses
  },
  CLEAR_VEHICLE_DATA(state) {
    state.vehicleEdited = cloneNested(state.emptyVehicle)
  },
  REMOVE_VEHICLE_RECORD(state, itemId) {
    const vehicleIndex = state.vehicles.findIndex(u => u.id === itemId)
    state.vehicles.splice(vehicleIndex, 1)
  },
}
