export default [
  {
    path: '/address/list',
    name: 'address-list',
    meta: {
      breadcrumb: [
        { text: 'order.orders_title' },
        { text: 'address.menu.title', active: true },
      ],
      pageTitle: 'address.menu.title',
      roles: ['ROLE_DISPATCHER'],
    },
    component: () => import('@/views/addresses/AddressList/AddressList'),
  },
  {
    path: '/address/edit/:id',
    name: 'address-edit',
    meta: {
      breadcrumb: [
        { text: 'order.orders_title' },
        { text: 'address.menu.title', to: '/address/list' },
        { text: 'address.menu.edit', active: true },
      ],
      pageTitle: 'address.menu.edit',
      roles: ['ROLE_DISPATCHER'],
    },
    component: () => import('@/views/addresses/AddressEdit/AddressEdit'),
  },
]
