import state from './moduleTeamState'
import mutations from './moduleTeamMutations'
import actions from './moduleTeamActions'
import getters from './moduleTeamGetters'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
