import axios from '@/libs/axios'

export default {
  getVehicle: id => axios.get(`/vehicle/${id}`),
  getVehicleStatuses: () => axios.get('/vehicle/statuses'),
  getVehicles: query => axios.get('/vehicle', { params: query }),
  getFreeVehicles: () => axios.get('/vehicle/find?freeOnly'),
  addVehicle: data => axios.post('/vehicle', data),
  updateVehicle: (id, data) => axios.patch(`/vehicle/${id}`, data),

  getTrackingIdentifiers: () => axios.get('/vehicle/tracking/vehicles'),
  getTrackingCurrentLocations: () => axios.get('/vehicle/tracking/current'),
}
