export default [
  {
    path: '/order/list',
    name: 'order-list',
    meta: {
      breadcrumb: [
        { text: 'order.orders_title' },
        { text: 'order.list.title', active: true },
      ],
      pageTitle: 'order.list.title',
      roles: ['ROLE_DISPATCHER', 'ROLE_PARAMEDIC'],
    },
    component: () => import('@/views/orders/OrderList/OrderList'),
  },
  {
    path: '/order/edit/:id',
    name: 'order-edit',
    meta: {
      roles: ['ROLE_DISPATCHER'],
    },
    component: () => import('@/views/orders/OrderEdit/OrderEdit'),
  },
  {
    path: '/order/view/:id/:tab?/:section?',
    name: 'order-view',
    meta: {
      roles: ['ROLE_DISPATCHER', 'ROLE_PARAMEDIC'],
    },
    component: () => import('@/views/orders/OrderView/OrderView'),
  },
]
