export default {
  SET_CUSTOMER_EDITED(state, customer) {
    state.customerEdited = customer
  },
  REMOVE_ADDRESS_BY_INDEX_FROM_CUSTOMER_EDITED(state, index) {
    state.customerEdited.addresses.splice(index, 1)
  },
  ADD_ADDRESS_TO_CUSTOMER_EDITED_ADDRESSES(state, address) {
    state.customerEdited.addresses.push(address)
  },
  SET_CUSTOMERS(state, customers) {
    state.customers = customers
  },
  ADD_CUSTOMER(state, payload) {
    state.customers.push(payload)
  },
  REMOVE_CUSTOMER_RECORD(state, itemId) {
    const customerIndex = state.customers.findIndex(u => u.id === itemId)
    state.customers.splice(customerIndex, 1)
  },
}
