const addressModel = {
  id: '',
  alias: '',
  name: '',
  address: '',
  zip: '',
  city: '',
  searchable: true,
  private: false,
}
export default {
  addressEdited: addressModel,
  addressModel,
  addresses: [],
  freeAddresses: [],
  addressStatuses: [],
}
