export default {
  report: {
    id: null,
    type: null,
    status: null,
    filePath: null,
    availableTo: null,
    requestedBy: {},
    customer: {},
    month: null,
    updatedAt: null,
    createdAt: null,
  },
  reports: [],
}
