import { cloneNested } from '@/helpers/helpers'

export default {
  SET_USER_EDITED(state, user) {
    state.userEdited = user
  },
  SET_USERS(state, users) {
    state.users = users
  },
  SET_DRIVERS(state, drivers) {
    state.drivers = drivers
  },
  SET_PARAMEDICS(state, paramedics) {
    state.paramedics = paramedics
  },
  SET_MEDICINE_DOCTORS(state, medicineDoctors) {
    state.medicineDoctors = medicineDoctors
  },
  CLEAR_VEHICLE_DATA(state) {
    state.userEdited = cloneNested(state.emptyUser)
  },
  REMOVE_USER_RECORD(state, itemId) {
    const userIndex = state.users.findIndex(u => u.id === itemId)
    state.users.splice(userIndex, 1)
  },
}
