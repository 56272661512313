/* eslint-disable import/no-mutable-exports */
// Pusher Channels

import Pusher from 'pusher-js'

const debug = false
Pusher.logToConsole = debug

const PusherClient = {
  pusher: null,
  bind(channelName, eventName, callback, context, i = 1) {
    if (i > 10) {
      return
    }
    setTimeout(() => {
      if (debug) console.log(`Try (${i}) to bind channel ${channelName}, event ${eventName}`)
      if (this.pusher && this.pusher.channels.find(channelName)) {
        this.pusher.channels.find(channelName).bind(eventName, callback, context)
      } else {
        this.bind(channelName, eventName, callback, context, i + 1)
      }
    }, 250)
  },
  unbind(channelName, eventName) {
    this.pusher.channels.find(channelName).unbind(eventName)
  },
  init(key, cluster = 'eu') {
    this.pusher = new Pusher(
      key,
      {
        cluster,
      },
    )
    this.notifications = this.pusher.subscribe('notifications')
    this.update = this.pusher.subscribe('update')
  },
}

export default PusherClient
