import reportRequests from '../../http/requests/report/report'

export default {
  requestReport: ({ commit }, payload) => new Promise((resolve, reject) => {
    reportRequests.requestReport(payload)
      .then(response => {
        commit('ADD_REPORT', response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  fetchReports: ({ commit }) => new Promise((resolve, reject) => {
    reportRequests.getReports()
      .then(response => {
        commit('SET_REPORTS', response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
}
