export default {
  SET_TEAM_EDITED(state, team) {
    state.teamEdited = team
  },
  SET_TEAMS(state, teams) {
    state.teams = teams
  },
  SET_ACTIVE_TEAMS(state, teams) {
    state.activeTeams = teams
  },
  ADD_TEAM(state, payload) {
    state.teams.push(payload)
  },
  SET_TEAM_STATISTICS_CURRENT(state, payload) {
    state.statisticsCurrent = payload
  },
}
