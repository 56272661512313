// payload is optional you have ability to send props to fetchTeamNameData
import settingsRequests from '@/http/requests/settings/settings'

export default {
  fetchTeamNames: ({ commit }, payload) => new Promise((resolve, reject) => {
    settingsRequests.getTeamNames(payload)
      .then(response => {
        commit('SET_TEAM_NAMES_DATA', response.data.items)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  fetchFreeTeamNames: ({ commit }, payload) => new Promise((resolve, reject) => {
    settingsRequests.getFreeTeamNames(payload)
      .then(response => {
        commit('SET_FREE_TEAM_NAMES_DATA', response.data)
        resolve(response.data)
      })
      .catch(err => reject(err))
  }),
  fetchTeamNameData: ({ commit }, id) => new Promise((resolve, reject) => {
    settingsRequests.getTeamName(id)
      .then(response => {
        commit('SET_TEAM_NAME_DATA', response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  addTeamName: (_, payload) => new Promise((resolve, reject) => {
    settingsRequests.addTeamName(payload)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  updateTeamName: ({ commit }, payload) => new Promise((resolve, reject) => {
    settingsRequests.updateTeamName(payload.id, payload.data)
      .then(response => {
        commit('SET_TEAM_NAME_DATA', payload.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  fetchChecklistDictionaries: ({ commit }, payload) => new Promise((resolve, reject) => {
    settingsRequests.getChecklistDictionaries(payload)
      .then(response => {
        commit('SET_CHECKLIST_DICTIONARIES_DATA', response.data.items)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  searchChecklistItems: (_, query) => new Promise((resolve, reject) => {
    settingsRequests.getChecklistDictionaries({ q: query })
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  fetchFreeChecklistDictionaries: ({ commit }, payload) => new Promise((resolve, reject) => {
    settingsRequests.getFreeChecklistDictionaries(payload)
      .then(response => {
        commit('SET_FREE_CHECKLIST_DICTIONARIES_DATA', response.data)
        resolve(response.data)
      })
      .catch(err => reject(err))
  }),
  fetchChecklistDictionaryData: ({ commit }, id) => new Promise((resolve, reject) => {
    settingsRequests.getChecklistDictionary(id)
      .then(response => {
        commit('SET_CHECKLIST_DICTIONARY_DATA', response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  addChecklistDictionary: (_, payload) => new Promise((resolve, reject) => {
    settingsRequests.addChecklistDictionary(payload)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  updateChecklistDictionary: ({ commit }, payload) => new Promise((resolve, reject) => {
    settingsRequests.updateChecklistDictionary(payload.id, payload.data)
      .then(response => {
        commit('SET_CHECKLIST_DICTIONARY_DATA', payload.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
}
