import state from './moduleSettingsState'
import mutations from './moduleSettingsMutations'
import actions from './moduleSettingsActions'
import getters from './moduleSettingsGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
