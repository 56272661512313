/*= ========================================================================================
  File Name: moduleAuthGetters.js
  Description: Auth Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== */

export default {
  getUserRoles: state => state.activeUser.roles,
  getUser: state => state.activeUser,
  getUserTeam: state => state.userTeam,
  isTeamLoaded: state => state.teamLoaded,
  getShowInitTeamPopup: state => state.showInitTeamPopup,
  getNotifications: state => state.notifications,
}
