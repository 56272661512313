import attachments from '@/http/requests/attachments'

export default {
  fetchAttachment: ({ commit }, attachmentId) => new Promise((resolve, reject) => {
    commit('SET_ATTACHMENT', {})
    attachments.getAttachment(attachmentId)
      .then(response => {
        commit('SET_ATTACHMENT', response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  fetchAttachments: ({ commit }, orderId) => new Promise((resolve, reject) => {
    attachments.getAttachments(orderId)
      .then(response => {
        commit('SET_ATTACHMENTS', response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  addAttachments: (_, { payload, orderId, progressCallback }) => new Promise((resolve, reject) => {
    attachments.postAttachment(orderId, payload, progressCallback)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  downloadAttachmentFile: (_, attachmentId) => new Promise((resolve, reject) => {
    attachments.getDownloadAttachmentFile(attachmentId)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  deleteAttachment: (_, id) => new Promise((resolve, reject) => {
    attachments.deleteAttachment(id)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
}
