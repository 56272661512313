import { empty } from '@/helpers/helpers'

/* eslint-disable no-param-reassign */
const hasAtLeastOneRole = (userRoles, requiredRoles) => {
  let granted = false

  if (typeof requiredRoles === 'undefined') {
    granted = true
  } else {
    for (let i = 0; i < userRoles.length; i += 1) {
      for (let j = 0; j < requiredRoles.length; j += 1) {
        if (userRoles.includes(requiredRoles[j])) {
          granted = true
          break
        }
      }
      if (granted) {
        break
      }
    }
  }
  return granted
}
const userRoles = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).roles : []
const visibleToRole = (el, binding, vnode) => {
  const elementRoles = binding.value
  const accessGranted = hasAtLeastOneRole(userRoles, elementRoles)
  if (empty(userRoles) || !accessGranted) {
    const comment = document.createComment(' ')
    Object.defineProperty(comment, 'setAttribute', {
      value: () => undefined,
    })
    vnode.elm = comment
    vnode.text = ' '
    vnode.isComment = true
    vnode.context = undefined
    vnode.tag = undefined
    vnode.data.directives = undefined

    if (vnode.componentInstance) {
      vnode.componentInstance.$el = comment
    }

    if (el.parentNode) {
      el.parentNode.replaceChild(comment, el)
    }
  }
}

export default {
  visibleToRole,
  hasAtLeastOneRole,
  userRoles,
}
