import axios from '@/libs/axios'

export default {
  getUsers: query => axios.get('/user', { params: query }),
  getFreeDrivers: () => axios.get('/user/find?role=DRIVER&freeOnly'),
  getFreeParamedics: () => axios.get('/user/find?role=PARAMEDIC&freeOnly'),
  getFreeMedicineDoctors: () => axios.get('/user/find?role=MEDICINE_DOCTOR&freeOnly'),
  getUserById: id => axios.get(`/user/${id}`),
  addUser: data => axios.post('/user', data),
  updateUser: (id, data) => axios.patch(`/user/${id}`, data),
  deleteUser: id => axios.delete(`/user/${id}`),
  updateMyPassword: data => axios.patch('/user/me/change-password', data),
}
