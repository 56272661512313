import Vue from 'vue'
import VueRouter from 'vue-router'
import guard from '@/guard'
import other from '@/router/other'
import routes from '@/router/routes'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...other,
    ...routes,
  ],
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login'] // future:'/register'
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem('userInfo')
  const routeRoles = to.meta.roles
  const userRoles = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).roles : []

  if (authRequired && !loggedIn) {
    return next('/login')
  }

  if (authRequired && typeof routeRoles !== 'undefined') {
    const accessGranted = guard.hasAtLeastOneRole(userRoles, routeRoles)
    if (!accessGranted) {
      window.toast.notify.warning('Access denied', `You don't have access to ${to.path}`)
      return next(from.path)
    }
  }
  return next()
})

router.afterEach(() => {
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.onError(error => {
  if (/loading chunk [\w\d-]+ failed./i.test(error.message)) {
    window.location.reload()
  }
})

export default router
