// axios
import axios from 'axios'

function readJsonFile(file) {
  const rawFile = new XMLHttpRequest()
  let fileContext
  rawFile.open('GET', file, false) // using synchronous call
  rawFile.onreadystatechange = () => {
    if (rawFile.readyState === 4) {
      if (rawFile.status === 200 || rawFile.status === 0) {
        fileContext = JSON.parse(rawFile.responseText)
      }
    }
  }
  rawFile.send(null)
  return fileContext
}
const appConfig = readJsonFile('/app.config.json')

const baseURL = appConfig.apiUrl

if (localStorage.getItem('tokens')) {
  axios.defaults.headers.common.Authorization = `Bearer ${JSON.parse(localStorage.getItem('tokens')).token}`
}

const axiosIns = axios.create({
  baseURL,
})

export default axiosIns
