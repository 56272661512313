import state from './moduleFilterState'
import mutations from './moduleFilterMutations'
import actions from './moduleFilterActions'
import getters from './moduleFilterGetters'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
