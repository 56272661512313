export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/pages/auth/Login.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('@/views/pages/auth/Logout.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/print',
    name: 'page-print',
    component: () => import('@/views/pages/print/Print.vue'),
    meta: {
      roles: ['ROLE_USER'],
    },
    children: [
      {
        path: '/print/order/:id',
        name: 'order-order',
        meta: {
          layout: 'full',
          roles: ['ROLE_DISPATCHER', 'ROLE_PARAMEDIC'],
        },
        component: () => import('@/views/orders/OrderPrint/OrderPrint'),
      },
    ],
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '*',
    redirect: 'dashboard',
  },
]
