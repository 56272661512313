import _ from 'lodash'

export default {
  SET_ORDER_EDITED(state, order) {
    state.orderEdited = _.merge(_.cloneDeep(state.defaultOrder), order)
  },
  MERGE_ORDER_EDITED(state, order) {
    state.orderEdited = _.merge(state.orderEdited, order)
  },
  ADD_ORDER_EDITED_ACTIVITY(state, activity) {
    state.orderEdited.orderActivities.push(activity)
  },
  SET_ORDER_EDITED_STATUS(state, status) {
    state.orderEdited.status = status
  },
  PUSH_PROVIDED_HEALTH_SERVICE_TO_ORDER_EDITED(state, service) {
    state.orderEdited.patientInfo.providedHealthServices.services.push(service)
  },
  REMOVE_PROVIDED_HEALTH_SERVICE_TO_ORDER_EDITED(state, key) {
    state.orderEdited.patientInfo.providedHealthServices.services.splice(key, 1)
  },
  CLEAR_ORDER_EDITED(state) {
    state.orderEdited = _.cloneDeep(state.defaultOrder)
  },
  SET_ORDERS(state, orders) {
    state.orders = orders
  },
  SET_FREE_ORDERS(state, orders) {
    state.freeOrders = orders
  },
  SET_ORDER_STATUSES(state, orderStatuses) {
    state.orderStatuses = orderStatuses
  },
  UPDATE_ORDER(state, data) {
    for (let i = 0; i < Object.entries(data).length; i += 1) {
      const [key, value] = Object.entries(data)[i]
      state.orderEdited[key] = value
    }
  },
  SET_ORDER_PICKUP_ADDRESS(state, pickupAddress) {
    state.orderEdited.pickupAddress = pickupAddress
  },
  SET_ORDER_DELIVERY_ADDRESS(state, deliveryAddress) {
    state.orderEdited.deliveryAddress = deliveryAddress
  },
  SET_ORDER_CUSTOMER_ADDRESS(state, customerAddress) {
    state.orderEdited.customerAddress = customerAddress
  },
  SET_ORDER_STATUS(state, newStatus) {
    state.orderEdited.status = newStatus
  },
  SET_ORDER_TEAM(state, newTeam) {
    state.orderEdited.team = newTeam
  },
  REMOVE_ORDER_RECORD(state, itemId) {
    const orderIndex = state.orders.findIndex(u => u.id === itemId)
    state.orders.splice(orderIndex, 1)
  },
  SET_ORDER_STATISTICS_BY_TRANSPORT_AT(state, statistics) {
    state.statisticsByTransportAt = statistics
  },
  ADD_ORDER_COMMENT(state, comment) {
    state.orderEdited.comments.push(comment)
  },
}
