import axios from '@/libs/axios'

export default {
  getTeam: id => axios.get(`/team/${id}`),
  getTeams: query => axios.get('/team', { params: query }),
  getActiveTeams: query => axios.get('/team/find', { params: query }),
  getTeamsForAssign: () => axios.get('/team/find?activeOnly'),
  addTeam: data => axios.post('/team', data),
  finishTeamWork: id => axios.patch(`/team/finish-work/${id}`),
  updateTeam: (id, data) => axios.patch(`/team/${id}`, data),
}
