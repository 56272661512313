import state from './moduleReportState'
import mutations from './moduleReportMutations'
import actions from './moduleReportActions'
import getters from './moduleReportGetters'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
