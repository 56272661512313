import { cloneNested } from '@/helpers/helpers'

const orderModel = {
  id: null,
  type: '',
  private: false,
  prioritySignals: false,
  covidPositive: false,
  isPrivate: false,
  returnTransport: false,
  patientOnBoard: false,
  transportAt: '',
  status: '',
  amountGross: 0,
  paymentMethod: null,
  commissionedBy: '',
  agreedWith: '',
  orderActivities: [],
  doctorUser: null,
  team: {
    teamName: {},
    vehicle: {},
    paramedicUser: {},
    driverUser: {},
  },
  customerAddress: {
    // id: null,
    // alias: null,
    // address: null,
    // city: null,
    // name: null,
    // searchable: false,
    // zip: null
  },
  pickupAddress: {
    // id: null,
    // alias: null,
    // address: null,
    // city: null,
    // name: null,
    // searchable: false,
    // zip: null
  },
  pickupDetails: null,
  deliveryAddress: {
    // id: null,
    // alias: null,
    // address: null,
    // city: null,
    // name: null,
    // searchable: false,
    // zip: null
  },
  patientInfo: {
    // surname: null,
    // name: null,
    age: null,
    sex: 'M',
    patientData: {
      noPesel: false,
      transportStatus: 'patientRemained',
      valuables: {
        identityDocument: null,
        other: false,
        otherItems: null,
      },
    },
    death: {
      time: null,
      deathOccurred: 'notOccurred',
      deathCertificateIssued: false,
    },
    summary: {
      medicalDocumentationIssued: 'notReceived',
      disinfection: false,
    },
    providedHealthServices: {
      services: [],
      activities: [],
      otherActivities: null,
    },
    diagnosis: {
      diagnosis: [],
      description: null,
    },
    healthCondition: {
      patientState: null,
      awareness: null,
      psychomotorAssessment: null,
      pupils: {
        reactionToLight: {
          left: '',
          right: '',
        },
        width: {
          left: '',
          right: '',
        },
      },
      intoxication: [],
      other: [],
      ekg: {
        list: [],
        other: null,
      },
      paresis: {
        upperLimb: {
          left: false,
          right: false,
        },
        lowerLimb: {
          left: false,
          right: false,
        },
      },
      pulse: {
        value: '',
        regular: true,
      },
      glasgowComaScale: {
        eyeResponse: 0,
        verbalResponse: 0,
        motorResponse: 0,
      },
      respiratorySystem: {
        respiratoryFailure: [],
        left: [],
        right: [],
        // left: {
        //   normalMurmur: false,
        //   whirring: false,
        //   wheezing: false,
        //   crackle: false,
        //   rattle: false,
        //   noMurmur: false,
        //   other: false
        // },
        // right: {
        //   normalMurmur: false,
        //   whirring: false,
        //   wheezing: false,
        //   crackle: false,
        //   rattle: false,
        //   noMurmur: false,
        //   other: false
        // }
      },
    },
    // pesel: null,
    // notes: null
  },
  comments: [
    { comment: null, author: null },
  ],
  deliveryDetails: null,
  updatedAt: '',
  createdAt: '',
}

const orderEdited = cloneNested(orderModel)
const defaultOrder = cloneNested(orderModel)

const statuses = [
  'draft',
  'received',
  'confirmed',
  'on_way_to_pickup_point',
  'at_pickup_point',
  'patient_pick_up',
  'on_way_to_delivery_point',
  'at_delivery_point',
  'patient_delivery',
  'patient_not_taken',
  'completed',
  'done',
  'cancelled',
]

const types = [
  'specialized_transport',
  'basic_transport',
  'sanitary_transport',
]

export default {
  orderEdited,
  defaultOrder,
  orders: [],
  totalOrdersCount: 1,
  orderStatuses: [],
  statisticsByTransportAt: {},
  statuses,
  types,
}
