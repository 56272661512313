import vehicleRequests from '../../http/requests/vehicle/vehicle'

export default {
  fetchVehicles: ({ commit }, payload) => new Promise((resolve, reject) => {
    vehicleRequests.getVehicles(payload)
      .then(response => {
        commit('SET_VEHICLES', response.data.items)
        resolve(response.data)
      })
      .catch(err => reject(err))
  }),
  fetchFreeVehicles: ({ commit }) => new Promise((resolve, reject) => {
    vehicleRequests.getFreeVehicles()
      .then(response => {
        commit('SET_FREE_VEHICLES', response.data)
        resolve(response.data)
      })
      .catch(err => reject(err))
  }),
  fetchVehicleById: ({ commit }, payload) => new Promise((resolve, reject) => {
    vehicleRequests.getVehicle(payload)
      .then(response => {
        commit('SET_VEHICLE_EDITED', response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  fetchVehicleStatuses: ({ commit }) => new Promise((resolve, reject) => {
    vehicleRequests.getVehicleStatuses()
      .then(response => {
        commit('SET_VEHICLE_STATUSES', response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  addVehicle: (_, payload) => new Promise((resolve, reject) => {
    vehicleRequests.addVehicle(payload)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  updateVehicle: (_, payload) => new Promise((resolve, reject) => {
    vehicleRequests.updateVehicle(payload.id, payload.data)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  deleteVehicle: ({ commit }, id) => new Promise((resolve, reject) => {
    vehicleRequests.deleteVehicle(id)
      .then(response => {
        commit('REMOVE_VEHICLE_RECORD', id)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),

  fetchTrackingIdentifiers: () => new Promise((resolve, reject) => {
    vehicleRequests.getTrackingIdentifiers()
      .then(response => {
        resolve(response.data)
      })
      .catch(err => reject(err))
  }),

  fetchTrackingCurrentLocations: () => new Promise((resolve, reject) => {
    vehicleRequests.getTrackingCurrentLocations()
      .then(response => {
        resolve(response.data)
      })
      .catch(err => reject(err))
  }),
}
