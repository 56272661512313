import state from './moduleVehicleState'
import mutations from './moduleVehicleMutations'
import actions from './moduleVehicleActions'
import getters from './moduleVehicleGetters'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
