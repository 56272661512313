import axios from '@/libs/axios'

export default {
  getOrders: (query = {}) => axios.get('/order/find', { params: query }),
  getOrderById: id => axios.get(`/order/${id}`),
  identifyOrderByNumber: number => axios.get(`/order/find-by-number/${number}`),
  addOrder: data => axios.post('/order', data),
  addOrderComment: (id, data) => axios.post(`/order/${id}/comment/add`, data),
  updateOrder: (id, data) => axios.put(`/order/${id}`, data),
  patchOrder: (id, data) => axios.patch(`/order/${id}`, data),
  updateOrderStatus: (id, status, data) => axios.patch(`/order/${id}/status/${status}`, data),
  updateOrderTeam: (id, data) => axios.patch(`/order/team/${id}`, data),
  updateOrderPatientData: (id, data) => axios.patch(`/order/patient-info/${id}`, data),
  deleteOrder: id => axios.delete(`/order/${id}`),
  getDiseases: query => axios.get(`/order/diseases-classification/${query}`),
  getSummaryByOrderId: id => axios.get(`/order/summary/by-order/${id}`),
  updateSummary: (id, data) => axios.put(`/order/summary/${id}`, data),
  requestOrderSummaryRecalculation: orderId => axios.get(`/order/summary/calculate/${orderId}`),
  requestOrderSummaryRecalculationForMonth: payload => axios.get(`/order/summary/calculate/month/${payload.month}${payload.customerId ? `/${payload.customerId}` : ''}`),

}
