import { cloneNested } from '@/helpers/helpers'

export default {
  SET_TEAM_NAMES_DATA(state, payload) {
    state.teamNames = payload
  },
  SET_FREE_TEAM_NAMES_DATA(state, payload) {
    state.freeTeamNames = payload
  },
  SET_TEAM_NAME_DATA(state, payload) {
    state.teamName = payload
  },
  CLEAR_TEAM_NAME_DATA(state) {
    state.teamName = cloneNested(state.emptyTeamName)
  },
  SET_CHECKLIST_DICTIONARIES_DATA(state, payload) {
    state.checklistDictionaries = payload
  },
  SET_FREE_CHECKLIST_DICTIONARIES_DATA(state, payload) {
    state.freeChecklistDictionaries = payload
  },
  SET_CHECKLIST_DICTIONARY_DATA(state, payload) {
    state.checklistDictionary = payload
  },
  CLEAR_CHECKLIST_DICTIONARY_DATA(state) {
    state.checklistDictionary = cloneNested(state.emptyChecklistDictionary)
  },
}
