const ordersFilter = {
  sortDir: 'asc',
  sortField: 'transportAt',
  limit: 25,
  page: 1,
  transportAtFrom: '',
  transportAtTo: '',
  statuses: [],
}

export default {
  'order-list': ordersFilter,
}
