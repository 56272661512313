import { empty } from '@/helpers/helpers'
import addressRequests from '../../http/requests/address/address'

export default {
  fetchAddresses: ({ commit }, payload) => new Promise((resolve, reject) => {
    addressRequests.getAddresses(payload)
      .then(response => {
        commit('SET_ADDRESSES', response.data.items)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  fetchFoundAddresses: (_, { queryString, query }) => new Promise((resolve, reject) => {
    addressRequests.findAddress(queryString, query)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  fetchAddressById: ({ commit }, payload) => new Promise((resolve, reject) => {
    addressRequests.getAddress(payload)
      .then(response => {
        const { data } = response
        if (!empty(data.customer)) {
          data.customer = data.customer.id
        }
        commit('SET_ADDRESS_EDITED', data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  addAddressFromOrder: (_, payload) => new Promise((resolve, reject) => {
    addressRequests.addAddress(payload)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  addAddress: ({ commit }, payload) => new Promise((resolve, reject) => {
    addressRequests.addAddress(payload)
      .then(response => {
        commit('ADD_ADDRESS', response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  updateAddress: (_, payload) => new Promise((resolve, reject) => {
    addressRequests.updateAddress(payload.id, payload.data)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  patchAddress: (_, payload) => new Promise((resolve, reject) => {
    addressRequests.patchAddress(payload.id, payload.data)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  deleteAddress: ({ commit }, id) => new Promise((resolve, reject) => {
    addressRequests.deleteAddress(id)
      .then(response => {
        commit('REMOVE_ADDRESS_RECORD', id)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),

  fetchAddressDetails: (_, payload) => new Promise((resolve, reject) => {
    addressRequests.getAddressDetails(payload.addressId)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  postAddressDetail: (_, payload) => new Promise((resolve, reject) => {
    addressRequests.postAddressDetail(payload.addressId, payload.data)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  patchAddressDetail: (_, payload) => new Promise((resolve, reject) => {
    addressRequests.patchAddressDetail(payload.addressId, payload.addressDetailId, payload.data)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
}
