import Vue from 'vue'
import { ToastPlugin, ModalPlugin, BootstrapVue } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueGeolocation from 'vue-browser-geolocation'

import i18n from '@/libs/i18n'
import guard from '@/guard'
import visibility from 'vue-visibility-change'
import router from './router'
import store from './store/store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/service-worker.js')
    .then(registration => {
      registration.update()
    })
}

// Global directives
Vue.directive('visible-to-role', {
  update: guard.visibleToRole,
})
Vue.use(visibility)
Vue.use(VueGeolocation)

// BSV Plugin Registration
Vue.use(BootstrapVue)
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
