import axios from '@/libs/axios'

export default {
  getAddress: id => axios.get(`/address/${id}`),
  findAddress: (searchString, query) => axios.get(`/address/find/${searchString}`, { params: query }),
  getAddresses: query => axios.get('/address', { params: query }),
  addAddress: data => axios.post('/address', data),
  updateAddress: (id, data) => axios.put(`/address/${id}`, data),
  patchAddress: (id, data) => axios.patch(`/address/${id}`, data),

  getAddressDetails: id => axios.get(`/address/${id}/details`),
  postAddressDetail: (id, data) => axios.post(`/address/${id}/details`, data),
  patchAddressDetail: (addressId, addressDetailId, data) => axios.patch(
    `/address/${addressId}/details/${addressDetailId}`,
    data,
  ),
}
