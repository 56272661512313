/* eslint-disable no-console */
// Beams

import * as PusherPushNotifications from '@pusher/push-notifications-web'

const isiOS = () => [
  'iPad Simulator',
  'iPhone Simulator',
  'iPod Simulator',
  'iPad',
  'iPhone',
  'iPod',
].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes('Mac') && 'ontouchend' in document)

const BeamsClient = {
  beamsClient: null,
  isStarted: false,
  registeringLock: false,
  init(beamsInstanceId) {
    if (beamsInstanceId && !isiOS()) {
      window.navigator.serviceWorker.ready.then(serviceWorkerRegistration => {
        this.beamsClient = new PusherPushNotifications.Client({
          instanceId: beamsInstanceId,
          serviceWorkerRegistration,
        })

        this.beamsClient
          .start()
          .then(() => {
            this.isStarted = true
          })
          .catch(e => { console.error('Could not start beams', e) })
      }).catch(() => { console.error('Can\'t get serviceWorker registration') })
    }
  },
  addBeamsDeviceInterest(interest, i = 1) {
    setTimeout(() => {
      if (this.isStarted && !this.registeringLock && i < 100) {
        this.registeringLock = true
        this.beamsClient
          .addDeviceInterest(interest)
          .then(() => {
            console.info(`Beams device interest ${interest} has been added.`)
          })
        this.registeringLock = false
      } else {
        this.addBeamsDeviceInterest(interest, i + 1)
      }
    }, 250)
  },
  clearBeamsDeviceInterests(i = 0) {
    if (this.isStarted) {
      this.beamsClient.clearDeviceInterests()
        .then(() => console.info('Beams all states cleared.'))
    } else if (i < 1000) {
      this.clearBeamsDeviceInterests(i + 1)
    }
  },
  stopBeams(i = 0) {
    if (this.isStarted) {
      this.beamsClient.stop()
        .then(() => console.info('Beams has been stopped.'))
    } else if (i < 1000) {
      this.stopBeams(i + 1)
    }
  },
}

export default BeamsClient
