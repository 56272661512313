import { orderRequests, orderStatisticsRequests } from '../../http/requests/order/order'

export default {
  fetchOrders: ({ commit }, query = {}) => new Promise((resolve, reject) => {
    orderRequests.getOrders(query)
      .then(response => {
        commit('SET_ORDERS', response.data.items)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  fetchOrderById: ({ commit, state }, id) => new Promise((resolve, reject) => {
    if (state.orderEdited.id !== null && parseInt(state.orderEdited.id, 10) !== parseInt(id, 10)) {
      commit('CLEAR_ORDER_EDITED')
    }
    orderRequests.getOrderById(id)
      .then(response => {
        commit('SET_ORDER_EDITED', response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  fetchOrderStatuses: ({ commit }) => new Promise((resolve, reject) => {
    orderRequests.getOrderStatuses()
      .then(response => {
        commit('SET_ORDER_STATUSES', response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  addOrder: (_, payload) => new Promise((resolve, reject) => {
    orderRequests.addOrder(payload)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  updateOrder: (_, payload) => new Promise((resolve, reject) => {
    orderRequests.updateOrder(payload.id, payload.data)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  patchOrder: ({ commit }, payload) => new Promise((resolve, reject) => {
    orderRequests.patchOrder(payload.id, payload.data)
      .then(response => {
        commit('MERGE_ORDER_EDITED', payload.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  updateOrderStatus: ({ commit }, payload) => new Promise((resolve, reject) => {
    orderRequests.updateOrderStatus(payload.id, payload.data.status, payload.data)
      .then(response => {
        commit('ADD_ORDER_EDITED_ACTIVITY', payload.data)
        if (response.data.type === 'status_change') {
          commit('SET_ORDER_EDITED_STATUS', response.data.new)
        }
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  updateOrderTeam: ({ commit }, payload) => new Promise((resolve, reject) => {
    orderRequests.updateOrderTeam(payload.id, { team: payload.data.team.id ? payload.data.team.id : null })
      .then(response => {
        commit('SET_ORDER_TEAM', payload.data.team)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  updateOrderPatientData: (_, payload) => new Promise((resolve, reject) => {
    orderRequests.updateOrderPatientData(payload.id, payload.data)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  deleteOrder: ({ commit }, id) => new Promise((resolve, reject) => {
    orderRequests.deleteOrder(id)
      .then(response => {
        commit('REMOVE_ORDER_RECORD', id)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  fetchStatisticsByTransportAt: ({ commit }) => new Promise((resolve, reject) => {
    orderStatisticsRequests.byTransportAt()
      .then(response => {
        commit('SET_ORDER_STATISTICS_BY_TRANSPORT_AT', response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  fetchFinancialResultsByOrder: (_, month) => new Promise((resolve, reject) => {
    orderStatisticsRequests.financialResultsByOrder(month)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  }),
  addComment: ({ commit }, payload) => new Promise((resolve, reject) => {
    const commentData = {
      comment: payload.comment.comment,
    }
    commit('ADD_ORDER_COMMENT', payload.comment)
    orderRequests.addOrderComment(payload.id, commentData)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  identifyOrderByNumber: (_, number) => new Promise((resolve, reject) => {
    orderRequests.identifyOrderByNumber(number)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  searchDisease: (_, query) => new Promise((resolve, reject) => {
    orderRequests.getDiseases(query)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  fetchSummaryByOrderId: (_, orderId) => new Promise((resolve, reject) => {
    orderRequests.getSummaryByOrderId(orderId)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  updateSummary: (_, payload) => new Promise((resolve, reject) => {
    orderRequests.updateSummary(payload.id, payload.data)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  requestOrderSummaryRecalculation: (_, orderId) => new Promise((resolve, reject) => {
    orderRequests.requestOrderSummaryRecalculation(orderId)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  requestOrderSummaryRecalculationForMonth: (_, payload) => new Promise((resolve, reject) => {
    orderRequests.requestOrderSummaryRecalculationForMonth(payload)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
}
