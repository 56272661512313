import _ from 'lodash'

export default {
  SET_ATTACHMENT(state, payload) {
    state.attachment = _.mergeWith(_.cloneDeep(state.attachmentModel), payload)
  },
  SET_ATTACHMENTS(state, payload) {
    state.attachments = payload
  },
  CLEAR_ATTACHMENT(state) {
    state.attachment = _.cloneDeep(state.attachmentModel)
  },
}
