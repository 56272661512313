import { teamRequests, teamStatisticsRequests } from '../../http/requests/team/team'

export default {
  fetchTeams: ({ commit }, query = []) => new Promise((resolve, reject) => {
    teamRequests.getTeams(query)
      .then(response => {
        commit('SET_TEAMS', response.data.items)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  fetchActiveTeams: ({ commit }, query = []) => new Promise((resolve, reject) => {
    teamRequests.getActiveTeams(query)
      .then(response => {
        commit('SET_ACTIVE_TEAMS', response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  fetchDowntimeCostsByDay: (_, month) => new Promise((resolve, reject) => {
    teamStatisticsRequests.downtimeCostsByDay(month)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  }),
  fetchTeamsForAssign: ({ commit }) => new Promise((resolve, reject) => {
    teamRequests.getTeamsForAssign()
      .then(response => {
        commit('SET_TEAMS', response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  fetchTeamById: ({ commit }, payload) => new Promise((resolve, reject) => {
    teamRequests.getTeam(payload)
      .then(response => {
        commit('SET_TEAM_EDITED', response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  addTeam: (_, payload) => new Promise((resolve, reject) => {
    teamRequests.addTeam(payload)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  finishTeamWork: (_, payload) => new Promise((resolve, reject) => {
    teamRequests.finishTeamWork(payload)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  updateTeam: (_, payload) => new Promise((resolve, reject) => {
    teamRequests.updateTeam(payload.id, payload.data)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  fetchStatisticsCurrent: ({ commit }) => new Promise((resolve, reject) => {
    teamStatisticsRequests.current()
      .then(response => {
        commit('SET_TEAM_STATISTICS_CURRENT', response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
}
