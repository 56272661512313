import { cloneNested } from '@/helpers/helpers'

const vehicleModel = {
  id: '',
  brand: '',
  model: '',
  registrationNumber: '',
  sideNumber: '',
  phoneNumber: '',
  gpsIdentifier: '',
  status: '',
  checklistItems: [],
}

export default {
  vehicleEdited: cloneNested(vehicleModel),
  emptyVehicle: cloneNested(vehicleModel),
  vehicles: [],
  freeVehicles: [],
  vehicleStatuses: [],
}
