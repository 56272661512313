export default [
  {
    path: '/customer/list',
    name: 'customer-list',
    meta: {
      breadcrumb: [
        { text: 'order.orders_title' },
        { text: 'customer.menu.title', active: true },
      ],
      pageTitle: 'customer.menu.title',
      roles: ['ROLE_DISPATCHER'],
    },
    component: () => import('@/views/customers/CustomerList/CustomerList'),
  },
  {
    path: '/customer/edit/:id',
    name: 'customer-edit',
    meta: {
      roles: ['ROLE_ADMIN'],
    },
    component: () => import('@/views/customers/CustomerEdit/CustomerEdit'),
  },
]
