import { cloneNested } from '@/helpers/helpers'

const userModel = {
  password: '',
  teamLoginOnly: false,
  roles: [],
  hourlySalaryGross: 0.00,
  hourlySalaryForReadinessGross: 0.00,
}
export default {
  userEdited: cloneNested(userModel),
  emptyUser: cloneNested(userModel),
  users: [],
  drivers: [],
  paramedics: [],
  medicineDoctors: [],
}
