import axios from '@/libs/axios'

export default {
  getAttachment(attachmentId) {
    return axios.get(`/attachments/${attachmentId}`)
  },
  getAttachments(orderId) {
    return axios.get(`/order/${orderId}/attachments`)
  },
  deleteAttachment(attachmentId) {
    return axios.delete(`/order/attachments/${attachmentId}`)
  },
  getDownloadAttachmentFile(attachmentId) {
    return axios.get(`/order/attachments/${attachmentId}/download`, { responseType: 'blob' })
  },
  postAttachment(orderId, payload, progressCallback) {
    return axios.post(
      `/order/${orderId}/attachments`,
      payload,
      {
        onUploadProgress: progressCallback,
      },
    )
  },
}
