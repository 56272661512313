export default [
  {
    path: '/settings/checklist-dictionary/list',
    name: 'settings-checklist-dictionary-list',
    meta: {
      breadcrumb: [
        { text: 'conf.title' },
        { text: 'conf.settings.title' },
        { text: 'conf.settings.checklist-dictionary.title', active: true },
      ],
      pageTitle: 'conf.settings.checklist-dictionary.title',
      roles: ['ROLE_CONFIG_MANAGEMENT'],
    },
    component: () => import('@/views/settings/ChecklistDictionary/ChecklistDictionaryList/ChecklistDictionaryList'),
  },
  {
    path: '/settings/checklist-dictionary/edit/:id',
    name: 'settings-checklist-dictionary-edit',
    meta: {
      breadcrumb: [
        { text: 'conf.title' },
        { text: 'conf.settings.title' },
        { text: 'conf.settings.checklist-dictionary.title', to: '/settings/checklist-dictionary/list' },
        { text: 'conf.settings.checklist-dictionary.edit-title', active: true },
      ],
      pageTitle: 'conf.settings.checklist-dictionary.title',
      roles: ['ROLE_CONFIG_MANAGEMENT'],
    },
    component: () => import('@/views/settings/ChecklistDictionary/ChecklistDictionaryEdit/ChecklistDictionaryEdit'),
  },
  {
    path: '/settings/team-name/list',
    name: 'settings-team-name-list',
    meta: {
      breadcrumb: [
        { text: 'conf.title' },
        { text: 'conf.settings.title' },
        { text: 'conf.settings.team-name.title', active: true },
      ],
      pageTitle: 'conf.settings.team-name.title',
      roles: ['ROLE_CONFIG_MANAGEMENT'],
    },
    component: () => import('@/views/settings/TeamName/TeamNameList/TeamNameList'),
  },
  {
    path: '/settings/team-name/edit/:id',
    name: 'settings-team-name-edit',
    meta: {
      breadcrumb: [
        { text: 'conf.title' },
        { text: 'conf.settings.title' },
        { text: 'conf.settings.team-name.title', to: '/settings/team-name/list' },
        { text: 'conf.settings.team-name.edit-title', active: true },
      ],
      pageTitle: 'conf.settings.team-name.title',
      roles: ['ROLE_CONFIG_MANAGEMENT'],
    },
    component: () => import('@/views/settings/TeamName/TeamNameEdit/TeamNameEdit'),
  },
]
