export default {
  SET_ADDRESS_EDITED(state, address) {
    state.addressEdited = address
  },
  SET_ADDRESSES(state, addresses) {
    state.addresses = addresses
  },
  SET_FREE_ADDRESSES(state, addresses) {
    state.freeAddresses = addresses
  },
  SET_ADDRESS_STATUSES(state, addressStatuses) {
    state.addressStatuses = addressStatuses
  },
  ADD_ADDRESS(state, payload) {
    state.addresses.push(payload)
  },
  REMOVE_ADDRESS_RECORD(state, itemId) {
    const addressIndex = state.addresses.findIndex(u => u.id === itemId)
    state.addresses.splice(addressIndex, 1)
  },
}
