import Vue from 'vue'
import VueI18n from 'vue-i18n'
import ax from 'axios'
import moment from 'moment'
import axios from '../axios'

const appLocale = localStorage.getItem('lang') || 'pl'

Vue.use(VueI18n)

const pluralizationRules = {
  /**
     * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
     * @param choicesLength {number} an overall amount of available choices
     * @returns a final choice index to select plural word by
     */
  pl: (choice, choicesLength) => {
    // this === VueI18n instance, so the locale property also exists here

    if (choice === 0) {
      return 0
    }

    const teen = choice > 10 && choice < 20
    const endsWithOne = choice % 10 === 1

    if (choicesLength < 4) {
      return (!teen && endsWithOne) ? 1 : 2
    }
    if (!teen && endsWithOne) {
      return 1
    }
    if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
      return 2
    }

    return (choicesLength < 4) ? 2 : 3
  },
}

const i18n = new VueI18n({
  pluralizationRules,
  locale: appLocale,
  fallbackLocale: appLocale,
  messages: {},
})

let version = localStorage.getItem('version')
if (!version) {
  version = moment().unix()
}

ax.get(`${window.location.origin}/locales/${appLocale}.json?v=${version}`)
  .then(response => {
    localStorage.setItem('lang', appLocale)
    i18n.setLocaleMessage(appLocale, response.data)
    axios.defaults.headers.common['Accept-Language'] = appLocale
    document.querySelector('html').setAttribute('lang', appLocale)
  })

export default i18n
