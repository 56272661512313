export default [
  {
    path: '/user/list',
    name: 'user-list',
    meta: {
      breadcrumb: [
        { text: 'conf.user.title', active: true },
      ],
      pageTitle: 'conf.user.title',
      roles: ['ROLE_USER_MANAGEMENT'],
    },
    component: () => import('@/views/users/UserList/UserList'),
  },
  {
    path: '/user/edit/:id',
    name: 'user-edit',
    meta: {
      breadcrumb: [
        { text: 'conf.user.title', to: '/user/list' },
        { text: 'conf.user.edit_user', active: true },
      ],
      pageTitle: 'conf.user.title',
      roles: ['ROLE_USER_MANAGEMENT'],
    },
    component: () => import('@/views/users/UserEdit/UserEdit'),
  },
]
