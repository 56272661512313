import customerRequests from '../../http/requests/customer/customer'

export default {
  fetchCustomers: ({ commit }, payload) => new Promise((resolve, reject) => {
    customerRequests.getCustomers(payload)
      .then(response => {
        commit('SET_CUSTOMERS', response.data.items)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  fetchCustomerById: ({ commit }, payload) => new Promise((resolve, reject) => {
    customerRequests.getCustomer(payload)
      .then(response => {
        commit('SET_CUSTOMER_EDITED', response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  addCustomer: ({ commit }, payload) => new Promise((resolve, reject) => {
    customerRequests.addCustomer(payload)
      .then(response => {
        commit('ADD_CUSTOMER', response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  updateCustomer: (_, payload) => new Promise((resolve, reject) => {
    customerRequests.updateCustomer(payload.id, payload.data)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  deleteCustomer: ({ commit }, id) => new Promise((resolve, reject) => {
    customerRequests.deleteCustomer(id)
      .then(response => {
        commit('REMOVE_CUSTOMER_RECORD', id)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  fetchCustomerBillingStrategiesByCustomerId: (_, customerId) => new Promise((resolve, reject) => {
    customerRequests.findCustomerBillingStrategiesByCustomerId(customerId)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  updateCustomerBillingStrategies: (_, payload) => new Promise((resolve, reject) => {
    customerRequests.updateCustomerBillingStrategies(payload.id, payload.data)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
}
