export default [
  {
    path: '/vehicle/list',
    name: 'vehicle-list',
    meta: {
      breadcrumb: [
        { text: 'conf.title' },
        { text: 'conf.vehicle.title', active: true },
      ],
      pageTitle: 'conf.vehicle.title',
      roles: ['ROLE_DISPATCHER'],
    },
    component: () => import('@/views/vehicles/VehicleList/VehicleList'),
  },
  {
    path: '/vehicle/edit/:id',
    name: 'vehicle-edit',
    meta: {
      breadcrumb: [
        { text: 'conf.title' },
        { text: 'conf.vehicle.title', to: '/vehicle/list' },
        { text: 'conf.vehicle.edit_vehicle', active: true },
      ],
      pageTitle: 'conf.vehicle.title',
      roles: ['ROLE_CONFIG_MANAGEMENT'],
    },
    component: () => import('@/views/vehicles/VehicleEdit/VehicleEdit'),
  },
]
